
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import InputItem from '@/components/InputItem.vue'
import smsMixin from '@/mixins/sms'
import { mobileRegExp, emailRegExp, passwordRegExp } from '@/utils/reg'
import { Base64 } from 'js-base64'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'passportFindAccount',
  components: {
    'input-item': InputItem
  },
  mixins: [smsMixin, GlobalMixin]
})
export default class PassportFindAccountPage extends Vue {
  @Provide() public step: number = 0
  @Provide() public account: string = ''
  @Provide() public code: number = 0
  @Provide() public firstPass: string = ''
  @Provide() public secondPass: string = ''
  @Provide() public apiError: string = ''
  @Provide() public passError: string = ''
  @Provide() public secondError: string = ''
  @Provide() public isShowDialog: boolean = false
  @Provide() public waitTime: number = 2
  @Provide() public userInfo: any = {}
  @Provide() public isType: number = 1
  @Provide() public selectType: number = 1
  @Provide() public resetPwd: any = {}
  @Provide() public emailShow: string = ''
  @Provide() public lastMobile: string = ''

  @Emit()
  public created () {
    this.refresh()
  }

  @Emit()
  public refresh () {
    let id: any = ((this.$route as any).query as any).token
    if (!id) {
      return
    }
    // if (location.href.includes('?')) {
    const self = this
    this.step = 2
    this.isType = 2
    id = Base64.decode(id).split('&')
    let data = {
      account: '',
      email: '',
      token: '',
      emailShow: ''
    }
    console.log('aaa', id)
    id.map((item: string) => {
      const name = item.split('=')[0]
      const value = item.split('=')[1]
      switch (name) {
        case 'account':
          data.account = value
          self.account = value
          break
        case 'email':
          data.email = value
          break
        case 'token':
          data.token = value
          break
        case 'emailshow':
          data.emailShow = value
          break
      }
    })
    this.userInfo = data
    this.resetPwd = data
    // }
  }
  @Emit()
  public async firstStep () {
    this.apiError = this.mobileError()
    if (this.apiError) {
      return
    }
    try {
      const res = await httpHelper.get({
        url: 'Password/ResetChannel',
        data: { account: this.account }
      })
      if (res.code === 0) {
        this.userInfo = res.value || {}
        this.isType = res.value.phoneReset === 1 ? 1 : 2
        this.selectType = this.isType
        this.step += 1
      }
    } catch (err) {
      this.apiError = err.msg
    }
  }
  @Provide() public telError: string = ''
  @Emit()
  public async sendCode () {
    let isSecend = 0
    if (this.lastMobile === this.userInfo.phone) {
      isSecend = 1
    }
    this.lastMobile = this.userInfo.phone
    const [err, res] = await this.sendSMSCode(this.userInfo.phone, 6, 0)
    if (err && err.code && err.msg) {
      this.telError = err.msg
    }
  }
  @Emit()
  public selectShow () {
    if (this.userInfo.phoneReset && this.userInfo.emailReset) {
      this.isShowDialog = true
    }
  }
  @Emit()
  public selectHide () {
    this.isShowDialog = false
  }

  @Emit()
  public select (index: number) {
    this.selectType = index
  }
  @Emit()
  public selectDetermine () {
    this.isType = this.selectType
    this.selectHide()
  }

  @Emit()
  public async second () {
    if (this.isType === 1) {
      try {
        const res = await httpHelper.post({
          url: 'Password/ValidPhone',
          data: { Phone: this.userInfo.phone, SmsCode: this.code }
        })
        this.step += 1
        this.resetPwd = res.value
      } catch (err) {
        this.telError = err.msg
      }
    } else {
      try {
        const res = await httpHelper.post({
          url: 'Password/SendEmail',
          data: { account: this.userInfo.email }
        })
        this.resetPwd = res.value
        this.$toasted.show('已发送验证邮件至您的邮箱，请确认')
      } catch (err) {
        this.telError = err.msg
      }
    }
  }
  @Emit()
  public mobileError () {
    if (!this.account) {
      return '手机号/邮箱不能为空'
    }
    return (mobileRegExp.test(this.account) || emailRegExp.test(this.account)) ? '' : '您输入的手机/邮箱格式不正确，请重新输入'
  }
  @Emit()
  public passwordError () {
    if (!this.firstPass) {
      this.passError = '密码不能为空'
      return
    }
    if (passwordRegExp.test(this.firstPass)) {
      this.passError = ''
    } else {
      this.passError = '*密码须为8-16位字母、数字、半角符号中至少2种组合'
    }
  }
  @Emit()
  public passwordSecondError () {
    if (!this.secondPass) {
      this.secondError = '密码不能为空'
      return
    }
    if (this.secondPass === this.firstPass) {
      this.secondError = ''
    } else {
      this.secondError = '*确认密码与密码不符'
    }
  }

  @Emit()
  public setAccount (detail: any) {
    this.account = detail.value
  }

  @Emit()
  public setCode (detail: any) {
    this.code = detail.value
  }

  @Emit()
  public setFirstPassword (detail: any) {
    this.firstPass = detail.value
  }

  @Emit()
  public setSecondPassword (detail: any) {
    this.secondPass = detail.value
  }

  @Emit()
  public async submit () {
    try {
      const res = await httpHelper.post({
        url: 'Password/Reset',
        data: { password: Base64.encode(this.firstPass), token: this.resetPwd.token }
      })
      if (res.code === 0) {
        this.$toasted.show('密码修改成功！' + this.waitTime + '秒钟后自动返回首页').goAway(3000)
        this.getWaitTime()
      }
    } catch (err) {
      this.secondError = err.msg
    }
  }
  @Emit()
  public getWaitTime () {
    const self = this
    if (this.waitTime > 0) {
      setTimeout(() => {
        self.waitTime--
        self.getWaitTime()
      }, 1000)
    } else {
      location.replace(httpHelper.mRoot)
    }
  }
  @Emit()
  public goback () {
    switch (this.step) {
      case 0:
        this.$router.replace('/login')
        break
      case 1:
        this.step = 0
        break
      case 2:
        if (this.isType === 1) {
          this.step = 1
        } else {
          this.$router.replace('/login')
        }
        break
      default:
        this.$router.replace('/login')
    }
  }
}
